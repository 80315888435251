<template>
  <div class="login-page flex center">
    <div class="login-container relative" hidden>
      <div class="bg" :style="{ backgroundImage: `url(${ bg_image })` }"></div>

      <div class="relative">
        <div class="title-top flex">
          <div class="flex">
            <div class="icon flex mr">
              <img :src="getPlatformInfo.website_logo" width="24px" />
            </div>
            <span>{{ getPlatformInfo.login_description || "您还未登录，请在登录后进行操作 ~" }}</span>
          </div>

          <div class="form-title-top">{{ form_title }}</div>
        </div>

        <!-- 原用户名&密码登录 -->
        <!-- <div class="form username_login_form" v-show="activeTab === 'login_username'">
          <div class="form_item">
            <div class="label">用户名</div>
            <div class="input">
              <input type="text" v-model="loginForm.username" placeholder="请输入用户名" />
            </div>
          </div>

          <div class="form_item">
            <div class="label">密码</div>
            <div class="input">
              <input type="text" v-model="loginForm.password" placeholder="请输入密码" />
            </div>
          </div>

          <div class="form_item">
            <div class="label">图形验证码</div>
            <div class="input captcha_input relative">
              <input type="text" v-model="loginForm.captcha_value" placeholder="请输入图形验证码" />
              <img
                class="captcha_image"
                @click="GetImageCaptcha"
                :src="captcha_data"
                v-if="captcha_data"
              />
              <div class="captcha_image flex center _loading_anis" v-else>
                <span>·</span>
                <span>·</span>
                <span>·</span>
              </div>
            </div>
          </div>

          <bottom-bar
            v-model="agree"
            :userAgreement="userAgreement"
            button_left="phone"
            button_right="register"
            submit_text="立即登录"
            :submit_loading="submit_loading"
            :allowRegistry="allowRegistry"
            @click_left="interfaceToggle('手机号登录', 'login_phone')"
            @click_right="interfaceToggle('手机号注册', 'register')"
            @submit_="LoginByPassword"
          />
        </div>-->
        <!-- 现改成微信扫码登录 -->

        <!-- 微信扫码登录 -->
        <div class="form username_login_form" v-show="activeTab === 'login_username'">
          <div class="flex center">
            <img :src="codeImage" alt class="wechat_code" />
          </div>
          <bottom-bar
            v-model="agree"
            :userAgreement="userAgreement"
            button_left="phone"
            button_right="register"
            :button_show="false"
            :allowRegistry="allowRegistry"
            @click_left="interfaceToggle('手机号登录', 'login_phone')"
            @click_right="interfaceToggle('手机号注册', 'register')"
          />
        </div>
        <!-- 微信扫码登录 -->

        <div class="form phone_login_form" v-show="activeTab === 'login_phone'">
          <div class="form_item">
            <div class="label">手机号码</div>
            <div class="input">
              <input type="text" v-model="phoneForm.phone" placeholder="请输入手机号码" />
            </div>
          </div>

          <div class="form_item">
            <div class="label">图形验证码</div>
            <div class="input captcha_input relative">
              <input type="text" v-model="phoneForm.captcha_value" placeholder="请输入图形验证码" />
              <img
                class="captcha_image"
                @click="GetImageCaptcha"
                :src="captcha_data"
                v-if="captcha_data"
              />
              <div class="captcha_image flex center _loading_anis" v-else>
                <span>·</span>
                <span>·</span>
                <span>·</span>
              </div>
            </div>
          </div>

          <div class="form_item">
            <div class="label">短信验证码</div>
            <div class="input captcha_input relative">
              <input type="text" v-model="phoneForm.captcha" placeholder="请输入短信验证码" />

              <div
                class="captcha_image send_captcha flex center _loading_anis"
                v-show="send_loading"
              >
                <span>·</span>
                <span>·</span>
                <span>·</span>
              </div>

              <div
                class="captcha_image send_captcha flex center hover_color"
                v-show="!send_loading"
                @click="SendPhoneCaptcha(1)"
              >{{ captchaNumber ? captchaNumber + "s" : "发送验证码" }}</div>
            </div>
          </div>

          <bottom-bar
            v-model="agree"
            :userAgreement="userAgreement"
            button_left="username"
            button_right="register"
            submit_text="立即登录"
            :submit_loading="submit_loading"
            :allowRegistry="allowRegistry"
            @click_left="interfaceToggle('微信扫码登录', 'login_username')"
            @click_right="interfaceToggle('手机号注册', 'register')"
            @submit_="registerLoginByPhone('login_phone')"
          />
        </div>

        <div class="form register_form" v-show="activeTab === 'register' && allowRegistry">
          <div class="form_item">
            <div class="label">手机号码</div>
            <div class="input">
              <input type="text" v-model="phoneForm.phone" placeholder="请输入手机号码" />
            </div>
          </div>

          <div class="form_item">
            <div class="label">图形验证码</div>
            <div class="input captcha_input relative">
              <input type="text" v-model="phoneForm.captcha_value" placeholder="请输入图形验证码" />
              <img
                class="captcha_image"
                @click="GetImageCaptcha"
                :src="captcha_data"
                v-if="captcha_data"
              />
              <div class="captcha_image flex center _loading_anis" v-else>
                <span>·</span>
                <span>·</span>
                <span>·</span>
              </div>
            </div>
          </div>

          <div class="form_item">
            <div class="label">短信验证码</div>
            <div class="input captcha_input relative">
              <input type="text" v-model="phoneForm.captcha" placeholder="请输入短信验证码" />

              <div
                class="captcha_image send_captcha flex center _loading_anis"
                v-show="send_loading"
              >
                <span>·</span>
                <span>·</span>
                <span>·</span>
              </div>

              <div
                class="captcha_image send_captcha flex center hover_color"
                v-show="!send_loading"
                @click="SendPhoneCaptcha"
              >{{ captchaNumber ? captchaNumber + "s" : "发送验证码" }}</div>
            </div>
          </div>

          <bottom-bar
            v-model="agree"
            :userAgreement="userAgreement"
            button_left="username"
            button_right="phone"
            submit_text="立即注册"
            :submit_loading="submit_loading"
            :allowRegistry="allowRegistry"
            @click_left="interfaceToggle('微信扫码登录', 'login_username')"
            @click_right="interfaceToggle('手机号登录', 'login_phone')"
            @submit_="registerLoginByPhone('register')"
          />
        </div>
      </div>
    </div>
    <div class="icp_info flex center">
      <a :href="getPlatformInfo.icp_record_link" class="icp">{{ getPlatformInfo.icp_record_number }}</a>
      <a :href="getPlatformInfo.ga_record_link" class="ga flex ml">
        <img :src="getPlatformInfo.ga_record_icon" alt class="ga-icon" width="30px" />
        <span>{{ getPlatformInfo.ga_record_number }}</span>
      </a>
    </div>

    <div class="login-container relative">
      <div class="bg" :style="{ backgroundImage: `url(${ bg_image })` }"></div>

      <div class="relative">
        <div class="title-top flex">
          <div class="flex">
            <div class="icon flex mr">
              <img :src="getPlatformInfo.website_logo" width="24px" />
            </div>
            <span>{{ getPlatformInfo.login_description || "您还未登录，请在登录后进行操作 ~" }}</span>
          </div>
        </div>

        <!-- 登录模块组件（手机号登录/微信扫码登录/快捷注册） -->
        <div class="bottom-tabs flex center">
          <div
            class="tab-item"
            :class="{ active: el.label === currentTab }"
            @click="toggleTabItem(el.label)"
            v-for="(el, i) in bottomtabs"
            :key="i"
          >
            <div class="icon wx" v-html="el.icon"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <wx-code></wx-code> -->
  </div>
</template>

<script>
import store from "@/store"
import { Message } from "element-ui"
import bottomBar from "./components/bottomBar.vue"
import {
  GetImageCaptcha,
  SendPhoneCaptcha,
  RegistryByPhone,
  LoginByPassword,
  LoginByPhone,
  UserInfo,
} from "@/api/user"
import instance from "@/utils/request"
import { Confirm } from "@/utils/element"
import { mapGetters } from "vuex"
import WxCode from "@/components/wxCode.vue"

export default {
  components: { bottomBar, WxCode },

  data() {
    return {
      ...{
        bottomtabs: [
          {
            label: "wechat",
            icon: `<svg
                t="1723788075886"
                class="icon_inner"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="1539"
                width="50"
                height="50"
              >
                <path
                  d="M352.814545 385.396364m-33.512727 0a33.512727 33.512727 0 1 0 67.025455 0 33.512727 33.512727 0 1 0-67.025455 0Z"
                  fill="#50B674"
                  p-id="1540"
                />
                <path
                  d="M502.690909 384.465455m-33.512727 0a33.512727 33.512727 0 1 0 67.025454 0 33.512727 33.512727 0 1 0-67.025454 0Z"
                  fill="#50B674"
                  p-id="1541"
                />
                <path
                  d="M576.232727 534.341818m-23.272727 0a23.272727 23.272727 0 1 0 46.545455 0 23.272727 23.272727 0 1 0-46.545455 0Z"
                  fill="#50B674"
                  p-id="1542"
                />
                <path
                  d="M694.458182 536.203636m-23.272727 0a23.272727 23.272727 0 1 0 46.545454 0 23.272727 23.272727 0 1 0-46.545454 0Z"
                  fill="#50B674"
                  p-id="1543"
                />
                <path
                  d="M512 0C229.003636 0 0 229.003636 0 512s229.003636 512 512 512 512-229.003636 512-512S794.996364 0 512 0z m-87.505455 630.225455c-26.996364 0-48.407273-5.585455-75.403636-11.17091l-75.403636 37.236364 21.410909-64.232727c-53.992727-37.236364-85.643636-85.643636-85.643637-145.221818 0-102.4 96.814545-182.458182 215.04-182.458182 105.192727 0 198.283636 64.232727 216.901819 150.807273-6.516364-0.930909-13.963636-0.930909-20.48-0.93091-102.4 0-182.458182 76.334545-182.458182 170.356364 0 15.825455 2.792727 30.72 6.516363 44.683636-7.447273 0-13.963636 0.930909-20.48 0.93091z m314.647273 75.403636l15.825455 53.992727-58.647273-32.581818c-21.410909 5.585455-42.821818 11.170909-64.232727 11.170909-102.4 0-182.458182-69.818182-182.458182-155.461818s80.058182-155.461818 182.458182-155.461818c96.814545 0 182.458182 69.818182 182.458182 155.461818 0 47.476364-31.650909 90.298182-75.403637 122.88z"
                  fill="#50B674"
                  p-id="1544"
                />
              </svg>`,
          },
          {
            label: "phone",
            icon: `<svg
                t="1723788446165"
                class="icon_inner"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2574"
                width="32"
                height="32"
              >
                <path
                  d="M446.272 573.44a509.653333 509.653333 0 0 1-81.92-100.650667c67.786667-30.474667 112.138667-93.461333 112.138667-169.792C476.48 194.592 388.96 106.666667 280.906667 106.666667 170.058667 106.666667 85.333333 188.746667 85.333333 302.997333c0 158.250667 82.56 328.554667 200.618667 439.658667 100.010667 94.122667 258.986667 161.738667 413.461333 174.570667 0.832 0.074667 1.674667 0.106667 2.517334 0.106666h41.162666a30.517333 30.517333 0 0 0 0-61.045333h-39.872c-140.672-11.978667-286.026667-73.930667-375.456-158.090667-106.410667-100.16-181.408-254.837333-181.408-395.2 0-80.106667 56.981333-135.285333 134.549334-135.285333 74.282667 0 134.549333 60.533333 134.549333 135.285333 0 60.309333-40.896 107.989333-103.008 123.349334a30.517333 30.517333 0 0 0-19.786667 43.658666c27.573333 53.312 66.037333 104.426667 111.573334 147.690667 51.264 48.693333 109.941333 86.112 172.053333 108.16a30.506667 30.506667 0 0 0 40.362667-24.064c10.453333-67.093333 61.621333-114.026667 126.442666-114.026667 74.272 0 134.549333 60.544 134.549334 135.285334 0 25.578667-7.04 50.026667-20.149334 71.253333a30.528 30.528 0 0 0 51.925334 32.074667A196.096 196.096 0 0 0 938.666667 723.050667c0-108.394667-87.530667-196.330667-195.573334-196.330667-83.072 0-151.210667 52.384-177.621333 128.864-42.368-19.552-82.773333-47.541333-119.2-82.144z"
                  fill="#13227a"
                  p-id="2575"
                />
              </svg>`,
          },
        ],
        currentTab: "wechat",
      },
      ...{
        allowRegistry: 0, // 1表示开放注册功能
        activeTab: "login_phone", // login_phone / login_username / register
        bg_image: require("./../assets/pages/my_record/ball.svg"),
        form_title: "手机号快捷登录",

        submit_loading: false,
        send_loading: false,

        captcha_data: "",
        captcha_id: "",
        captcha_flag: true,

        loginForm: {
          username: "admin",
          password: "q@wer1234",
          captcha_value: "",
        },

        phoneForm: {
          phone: "14138742837",
          captcha_value: "",
          captcha: "",
        },

        // 短信验证码倒计时
        captchaInterval: null,
        captchaNumber: 0,
      },

      // 微信扫码登录相关
      ...{
        expirationTime: 300, // 二维码有效期
        remainingTime: 300, // 剩余时间
        qrCodeTimer: null, // 二维码过期计时器
        pollingTimer: null, // 轮询计时器
        lastActiveTime: Date.now(), // 上次活跃时间
        scanResult: null, // 扫码结果
        pollingInProgress: false, // 标记轮询是否进行中
        codeImage: "", // 二维码图片URL
        ticket: "",
      },

      // 用户协议
      userAgreement: [],
      agree: false,
    }
  },
  computed: {
    ...mapGetters(["getPlatformInfo"]),
  },
  methods: {
    toggleTabItem(tabItem) {
      this.currentTab = tabItem
    },

    ...{
      // 表单验证
      vaildForm_(ruleForm) {
        // 校验手机号
        if (ruleForm.phone !== undefined) {
          if (!ruleForm.phone.trim() || !/^1\d{10}$/.test(ruleForm.phone)) {
            Message.warning("请填写正确的手机号")
            return false
          }
        }

        // 校验用户名
        if (ruleForm.username !== undefined) {
          if (!ruleForm.username.trim()) {
            Message.warning("用户名不能为空")
            return false
          }
        }

        // 校验密码
        if (ruleForm.password !== undefined) {
          if (!ruleForm.password.trim()) {
            Message.warning("密码不能为空")
            return false
          }
        }

        // 校验图形验证码
        if (ruleForm.captcha_value !== undefined) {
          if (!ruleForm.captcha_value.trim()) {
            Message.warning("图形验证码不能为空")
            return false
          }
        }

        // 校验短信验证码
        if (ruleForm.captcha !== undefined) {
          if (!ruleForm.captcha.trim()) {
            Message.warning("短信验证码不能为空")
            return false
          }
        }

        return true
      },

      // 获取图形验证码
      async GetImageCaptcha() {
        if (!this.captcha_flag) return false

        this.captcha_data = ""
        this.captcha_id = ""
        this.captcha_flag = false

        const res = await GetImageCaptcha()

        this.captcha_data = res.data.captcha_data
        this.captcha_id = res.data.captcha_id
        this.captcha_flag = true
      },

      // 发送验证码到手机
      async SendPhoneCaptcha(is_login = 0) {
        if (this.captchaNumber) return false

        const { phone, captcha_value } = this.phoneForm
        if (!this.vaildForm_({ phone, captcha_value })) return false
        this.send_loading = true

        await SendPhoneCaptcha({
          ...this.phoneForm,
          captcha_id: this.captcha_id,
          captcha: null,
          is_login, // 1 用于登录，0 其他
        })
          .then((res) => {
            // 判断
            this.send_loading = false
            if (res.data && res.data.allowRegistry === 1) {
              this.$message.warning(res.msg)
              this.interfaceToggle("手机号注册", "register")
              return
            } else if (res.data && res.data.allowRegistry === 0) {
              this.$message.warning("该号码尚未注册")
              return
            }

            // 成功后，开启60秒倒计时
            this.captchaNumber = 60
            this.captchaInterval = setInterval(() => {
              if (this.captchaNumber === 1) {
                clearInterval(this.captchaInterval)
              }

              this.captchaNumber--
            }, 1000)

            Message.success("验证码已发送，注意查收")
          })
          .catch(() => {
            this.send_loading = false
            this.GetImageCaptcha()
          })
      },

      // 重置表单信息、定时器以及loading
      resetFormLoading(form_title, activeTab) {
        if (activeTab === "login_username") {
          if (!this.agree)
            return Confirm("请先阅读并在勾选同意用户协议后才能获取微信二维码")
          this.clearTimers()
          this.fetchQRCode()
        }

        this.form_title = form_title
        this.activeTab = activeTab

        this.submit_loading = false
        this.send_loading = false
        clearInterval(this.captchaInterval)
        this.captchaInterval = null
        this.captchaNumber = 0

        if (activeTab === "login_phone") {
          this.GetImageCaptcha()
          this.phoneForm = {
            phone: "",
            captcha_value: "",
            captcha: "",
          }
        }
      },

      // ----------------------------------------- -+

      // 通过手机号注册 / 登录
      async registerLoginByPhone(type) {
        if (this.submit_loading) return false

        const { phone, captcha } = this.phoneForm
        if (!this.vaildForm_({ phone, captcha })) return false
        this.submit_loading = true

        let apiFunction, message, _callback
        if (type === "login_phone") {
          apiFunction = LoginByPhone
          message = "登录成功"

          _callback = ({ data, msg }) => {
            this.localStorageJump(data.token)
          }
        } else {
          apiFunction = RegistryByPhone
          message = "注册成功"
        }

        await apiFunction({ phone, captcha })
          .then((res) => {
            Message.success(message)
            this.resetFormLoading("手机号快捷登录", "login_phone")
            this.submit_loading = false

            _callback && _callback(res)
          })
          .catch(() => {
            this.submit_loading = false
          })
      },

      // 通过微信扫码登录
      async LoginByPassword() {
        if (this.submit_loading) return false
        if (!this.vaildForm_(this.loginForm)) return false
        this.submit_loading = true

        const captcha_id = this.captcha_id
        await LoginByPassword({ ...this.loginForm, captcha_id })
          .then((res) => {
            Message.success("登录成功")

            this.localStorageJump(res.data.token)
          })
          .catch(() => {
            this.GetImageCaptcha()
            this.submit_loading = false
          })
      },

      // ----------------------------------------- -+

      // 界面类型切换
      interfaceToggle(form_title, activeTab) {
        if (form_title === "手机号注册" && !this.allowRegistry) {
          return Confirm("平台处于内测模式中，注册功能暂不开放")
        }

        if (this.submit_loading) return false
        this.resetFormLoading(form_title, activeTab)
      },

      // 本地存储token/info & 跳转至首页
      async localStorageJump(token) {
        store.dispatch("setTokenSync", token)

        const res = await UserInfo()
        store.dispatch("setInfoSync", res.data.sys_user)
        this.$router.replace("/my_record")
      },
    },

    // 微信扫码登录相关
    ...{
      async fetchQRCode() {
        const { data } = await instance.get("/GetWechatQRCodeForLogin")

        this.ticket = data.ticket
        this.codeImage =
          "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + data.ticket
        this.remainingTime = this.expire_seconds
        this.startQRCodeTimer()
        this.startPolling()
      },
      startQRCodeTimer() {
        this.qrCodeTimer = setInterval(() => {
          this.remainingTime -= 1
          if (this.remainingTime <= 0) {
            this.clearQRCodeTimer()
            this.fetchQRCode()
          }
        }, 1000)
      },
      clearQRCodeTimer() {
        if (this.qrCodeTimer) {
          clearInterval(this.qrCodeTimer)
          this.qrCodeTimer = null
        }
      },
      clearPollingTimer() {
        if (this.pollingTimer) {
          clearInterval(this.pollingTimer)
          this.pollingTimer = null
        }
      },
      startPolling() {
        if (this.pollingTimer) {
          clearInterval(this.pollingTimer)
        }
        this.pollingTimer = setInterval(async () => {
          if (!this.pollingInProgress) {
            this.pollingInProgress = true
            try {
              await this.fetchScanResult()
            } finally {
              this.pollingInProgress = false
            }
          }
        }, 2000)
      },
      handleVisibilityChange() {
        if (document.hidden) {
          this.clearTimers()
          this.lastActiveTime = Date.now()
        } else {
          if (this.form_title === "微信扫码登录") {
            const currentTime = Date.now()
            const timeElapsed = Math.floor(
              (currentTime - this.lastActiveTime) / 1000
            )
            this.remainingTime -= timeElapsed
            if (this.remainingTime > 0) {
              this.startQRCodeTimer()
              this.startPolling()
            } else {
              this.fetchQRCode()
            }
          }
        }
      },
      async fetchScanResult() {
        const { msg, data } = await instance.get("/IdentifyWechatTicketLogin", {
          params: { ticket: this.ticket },
          loading: false,
        })
        console.log(msg)

        if (data && data.token) {
          store.dispatch("setInfoSync", data.sys_user)
          store.dispatch("setTokenSync", data.token)
          Message.success("登录成功")
          this.$router.replace("/my_record/my_record_index")
        }
      },
      clearTimers() {
        this.clearQRCodeTimer()
        this.clearPollingTimer()
      },
    },

    // 协议
    async ListRichTextLogin() {
      const { data } = await instance.get("/ListRichText", {
        params: {
          position: "登录页",
        },
      })

      this.userAgreement = data
      console.log({ ...data })
    },
  },

  async created() {
    // 动态显示注册功能
    const { data } = await instance.get("/GetConfigAllowRegistry")
    this.allowRegistry = data.allowRegistry

    this.GetImageCaptcha()
    this.ListRichTextLogin()
    if (this.form_title !== "微信扫码登录") {
      return this.GetImageCaptcha()
    }

    this.fetchQRCode()
    document.addEventListener("visibilitychange", this.handleVisibilityChange)
  },

  beforeDestroy() {
    clearInterval(this.captchaInterval)
    this.captchaInterval = null

    this.clearTimers()
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    )
  },
}
</script>

<style lang="less" scoped>
.bottom-tabs {
  .tab-item {
    width: 66px;
    height: 66px;
    padding: 8px;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;
    margin: 0 2px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
  }

  .icon {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tab-item.active {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
</style>

<style lang="less" scoped>
@import url(./../styles/variable.less);

.icp_info {
  position: fixed;
  bottom: 25px;

  a {
    text-decoration: none;

    &:hover,
    &:hover span {
      color: steelblue;
      text-decoration: underline;
    }
  }
}

.wechat_code {
  width: 70%;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.form {
  animation: _show_translateY 0.4s;
}

.form-title-top {
  color: @white6;
  font-size: 14px;
  padding: 0 12px;
  line-height: 30px;
  border-radius: 5px;
  background-color: @white02;
}

._loading_anis {
  padding-bottom: 5px;
  user-select: none;

  span:nth-child(1) {
    animation: _loading_anis 0.4s infinite;
  }

  span:nth-child(2) {
    animation: _loading_anis 0.4s infinite;
    animation-delay: 0.1s;
  }

  span:nth-child(3) {
    animation: _loading_anis 0.4s infinite;
    animation-delay: 0.2s;
  }
}

.hover_filter {
  transition: 0.2s;

  &:hover {
    filter: brightness(120%) !important;
  }

  &:active {
    filter: brightness(80%) !important;
  }
}

.hover_color {
  transition: 0.2s;

  &:hover {
    background-color: @white05 !important;
  }

  &:active {
    background-color: @white1 !important;
  }
}

.login-page {
  width: 100%;
  height: 100%;

  .login-container {
    width: 620px;
    border-radius: @radius;
    background-color: @backgroundColor;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 40px;

    .form_item {
      margin-bottom: 20px;

      .label {
        margin-bottom: 10px;
      }

      .input input {
        width: 100%;
        height: @itemHeight;
        border-radius: 5px;
        border: none;
        background-color: rgba(255, 255, 255, 0.06);
        padding: 0 20px;
        font-size: 15px;
      }

      .captcha_input {
        input {
          padding-right: 180px;
        }

        .captcha_image {
          position: absolute;
          bottom: 0;
          right: 0;
          border-radius: 5px;
          background-color: rgba(255, 255, 255, 0.04);
          width: 160px;
          height: 100%;
          cursor: pointer;
        }

        .send_captcha {
          background-color: transparent;
          transition: 0.2s;

          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 70%;
            top: 15%;
            left: 0;
            background-image: linear-gradient(
              to bottom,
              transparent,
              rgba(255, 255, 255, 0.1),
              transparent
            );
          }
        }
      }
    }

    .title-top {
      font-size: 20px;
      padding-bottom: 25px;
      margin-bottom: 25px;
      border-bottom: @borderBottom;

      span {
        color: @white6;
      }
    }

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      background: bottom right no-repeat;
      transform: scale(1.4);
      opacity: 0.02;
    }
  }

  .theme_color {
    color: @themeColor !important;
  }
}
</style>
