import store from "@/store"
import instance from "@/utils/request"
import { xwwwForm, formData } from "@/utils/request_config"

const config = { ...xwwwForm, loading: false }

// 获取图形验证码图像url
export const GetImageCaptcha = () => instance.get("/GetImageCaptcha", config)
// 根据手机号发送验证码
export const SendPhoneCaptcha = (data) => instance.post("/SendPhoneCaptcha", data, config)
// 通过手机号注册
export const RegistryByPhone = (data) => instance.post("/RegistryByPhone", data, config)
// 通过用户名密码登录
export const LoginByPassword = (data) => instance.post("/LoginByPassword", data, config)
// 通过手机号登录
export const LoginByPhone = (data) => instance.post("/LoginByPhone", data, config)

// 获取用户信息
export const UserInfo = () => instance.get("/UserInfo")
// 上传文件
export const UploadLocalstorage = (data) => instance.post("/UploadLocalstorage", data, { ...formData })
// 修改个人资料
export const UserInfoUpdate = (data) => instance.post("/UserInfoUpdate", data, config)
// 修改密码
export const UserPasswordUpdate = (data) => instance.post("/UserPasswordUpdate", data, { ...xwwwForm })
// 修改手机号
export const UserPhoneUpdate = (data) => instance.post("/UserPhoneUpdate", data, { ...xwwwForm })
// 获取我的徽章
export const UserBadge = () => instance.get(`/UserBadge?sys_user_id=${store.getters.getInfo.id}`)
// 获取我的征程
export const UserJourney = ({ year, month }) => instance.get(`/UserJourney?year=${year}&month=${month}`)
// 获取我的流水记录
export const ListSerialNumber = ({ page_num, page_size, type }) => instance.get(`/ListSerialNumber?page_num=${page_num}&page_size=${page_size}&type=${type}`)
// 列出自己的申请
export const ListMyApply = ({ page_num, page_size }) => instance.get(`/ListMyApply?page_num=${page_num}&page_size=${page_size}`)
// 申请创作者身份
export const SubmitAuthorApply = (data) => instance.post("/SubmitAuthorApply", data, { ...formData })
// 创作者提交靶机
export const SubmitATargetMachine = (data) => instance.post("/SubmitATargetMachine", data, { ...formData })
// 提交意见反馈
export const AddFeedBack = (data) => instance.post("/AddFeedBack", data, { ...formData })
// 查询微信绑定状态
export const CheckUserBindWechat = () => instance.get("/CheckUserBindWechat")
// 获取微信二维码
export const GetWechatQRCodeForBind = () => instance.get("/GetWechatQRCodeForBind")
// 查询扫码结果
export const IdentifyWechatTicketBind = (ticket) => instance.get("/IdentifyWechatTicketBind?ticket=" + ticket, {
  loading: false
})
// 取消微信绑定
export const WechatUnbind = () => instance.get("/WechatUnbind")
// 用户注销
export const DeleteUserAccount = data => instance.post("/DeleteUserAccount", data, { ...formData })

// 获取我的金币详情（列表）
export const UserShopInfo = params => instance.get("/UserShopInfo", { params })
