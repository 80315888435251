export default {
  path: '/activity',
  name: 'activity',
  redirect: '/activity/gold_products',
  component: () => import(`@/layout/activity`),
  children: [
    {
      path: '/activity/gold_products',
      name: 'gold_products',
      component: () => import(`@/layout/activity/gold_products`),
    },
    {
      path: '/activity/invite_link',
      name: 'invite_link',
      component: () => import(`@/layout/activity/invite_link`),
    },
    {
      path: '/activity/exchange',
      name: 'exchange',
      component: () => import(`@/layout/activity/exchange`),
    },
  ]
}
