<template>
  <div class="login-page flex center h-[calc(100vh-25px)]">
    <!-- 网站备案信息 -->
    <div class="icp_info flex center text-sm">
      <a :href="getPlatformInfo.icp_record_link" class="icp" target="_blank">{{
        getPlatformInfo.icp_record_number
      }}</a>
      <a
        :href="getPlatformInfo.ga_record_link"
        class="ga flex ml"
        target="_blank"
      >
        <img
          :src="getPlatformInfo.ga_record_icon"
          alt
          class="ga-icon"
          width="30px"
        />
        <span>{{ getPlatformInfo.ga_record_number }}</span>
      </a>
    </div>

    <!-- 登录相关核心容器 -->
    <div class="login-container relative">
      <div class="bg" :style="{ backgroundImage: `url(${bg_image})` }"></div>

      <div class="relative">
        <div class="title-top flex">
          <div class="flex">
            <div class="icon flex mr">
              <img :src="getPlatformInfo.website_logo" width="24px" />
            </div>
            <span class="text-lg">{{ getPlatformInfo.login_description || "登录/注册" }}</span>
          </div>
        </div>

        <div class="bottom-tabs flex">
          <div
            class="tab-item"
            :class="{ active: el.label === currentTab, ...el.classes }"
            @click="toggleTabItem(el.label)"
            v-for="(el, i) in bottomtabs"
            :key="i"
          >
            <!-- <div class="icon wx" v-html="el.icon"></div> -->
            <div class="text">{{ el.name }}</div>
          </div>
        </div>

        <!-- 登录模块组件（手机号登录/微信扫码登录/快捷注册） -->
        <div class="tab-container">
          <!-- 微信扫码登录 -->
          <template v-if="currentTab === 'wechat'">
            <wx-code class="flex center" uri_type="WechatLogin"></wx-code>
            <div class="flex center mt2">打开微信扫一扫，快速登录</div>
          </template>
          <!-- 手机号登录 -->
          <template v-if="currentTab === 'phone'">
            <phone-login :agree="agree"></phone-login>
            <user-agreement
              v-model="agree"
              :userAgreement="userAgreement"
            ></user-agreement>
          </template>
          <!-- 注册 -->
          <template v-if="currentTab === 'registry'">
            <!-- <registry :agree="agree" @toggleTab="toggleTabItem"></registry> -->
            <!-- <user-agreement v-model="agree" :userAgreement="userAgreement"></user-agreement> -->
          </template>
        </div>
      </div>
    </div>

    <!-- 
      alert == 1 && msg => 弹出弹窗
      is_registry == 1 => 表示未注册，需要跳转到注册tab
    -->
  </div>
</template>

<script>
import instance from "@/utils/request";
import WxCode from "@/components/wxCode.vue";
import { mapGetters } from "vuex";
import Registry from "./components/registry.vue";
import PhoneLogin from "./components/phoneLogin.vue";
import UserAgreement from "@/components/userAgreement.vue";

export default {
  components: { WxCode, Registry, PhoneLogin, UserAgreement },

  data() {
    return {
      bg_image: require("./../assets/pages/my_record/ball.svg"),
      bottomtabs: [
        {
          label: "phone",
          name: "手机号码登录",
          icon: `<svg
                t="1723788446165"
                class="icon_inner"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2574"
                width="32"
                height="32"
              >
                <path
                  d="M446.272 573.44a509.653333 509.653333 0 0 1-81.92-100.650667c67.786667-30.474667 112.138667-93.461333 112.138667-169.792C476.48 194.592 388.96 106.666667 280.906667 106.666667 170.058667 106.666667 85.333333 188.746667 85.333333 302.997333c0 158.250667 82.56 328.554667 200.618667 439.658667 100.010667 94.122667 258.986667 161.738667 413.461333 174.570667 0.832 0.074667 1.674667 0.106667 2.517334 0.106666h41.162666a30.517333 30.517333 0 0 0 0-61.045333h-39.872c-140.672-11.978667-286.026667-73.930667-375.456-158.090667-106.410667-100.16-181.408-254.837333-181.408-395.2 0-80.106667 56.981333-135.285333 134.549334-135.285333 74.282667 0 134.549333 60.533333 134.549333 135.285333 0 60.309333-40.896 107.989333-103.008 123.349334a30.517333 30.517333 0 0 0-19.786667 43.658666c27.573333 53.312 66.037333 104.426667 111.573334 147.690667 51.264 48.693333 109.941333 86.112 172.053333 108.16a30.506667 30.506667 0 0 0 40.362667-24.064c10.453333-67.093333 61.621333-114.026667 126.442666-114.026667 74.272 0 134.549333 60.544 134.549334 135.285334 0 25.578667-7.04 50.026667-20.149334 71.253333a30.528 30.528 0 0 0 51.925334 32.074667A196.096 196.096 0 0 0 938.666667 723.050667c0-108.394667-87.530667-196.330667-195.573334-196.330667-83.072 0-151.210667 52.384-177.621333 128.864-42.368-19.552-82.773333-47.541333-119.2-82.144z"
                  fill="#13227a"
                  p-id="2575"
                />
              </svg>`,
        },
        {
          label: "wechat",
          name: "微信扫码登录",
          icon: `<svg
                t="1723788075886"
                class="icon_inner"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="1539"
                width="50"
                height="50"
              >
                <path
                  d="M352.814545 385.396364m-33.512727 0a33.512727 33.512727 0 1 0 67.025455 0 33.512727 33.512727 0 1 0-67.025455 0Z"
                  fill="#50B674"
                  p-id="1540"
                />
                <path
                  d="M502.690909 384.465455m-33.512727 0a33.512727 33.512727 0 1 0 67.025454 0 33.512727 33.512727 0 1 0-67.025454 0Z"
                  fill="#50B674"
                  p-id="1541"
                />
                <path
                  d="M576.232727 534.341818m-23.272727 0a23.272727 23.272727 0 1 0 46.545455 0 23.272727 23.272727 0 1 0-46.545455 0Z"
                  fill="#50B674"
                  p-id="1542"
                />
                <path
                  d="M694.458182 536.203636m-23.272727 0a23.272727 23.272727 0 1 0 46.545454 0 23.272727 23.272727 0 1 0-46.545454 0Z"
                  fill="#50B674"
                  p-id="1543"
                />
                <path
                  d="M512 0C229.003636 0 0 229.003636 0 512s229.003636 512 512 512 512-229.003636 512-512S794.996364 0 512 0z m-87.505455 630.225455c-26.996364 0-48.407273-5.585455-75.403636-11.17091l-75.403636 37.236364 21.410909-64.232727c-53.992727-37.236364-85.643636-85.643636-85.643637-145.221818 0-102.4 96.814545-182.458182 215.04-182.458182 105.192727 0 198.283636 64.232727 216.901819 150.807273-6.516364-0.930909-13.963636-0.930909-20.48-0.93091-102.4 0-182.458182 76.334545-182.458182 170.356364 0 15.825455 2.792727 30.72 6.516363 44.683636-7.447273 0-13.963636 0.930909-20.48 0.93091z m314.647273 75.403636l15.825455 53.992727-58.647273-32.581818c-21.410909 5.585455-42.821818 11.170909-64.232727 11.170909-102.4 0-182.458182-69.818182-182.458182-155.461818s80.058182-155.461818 182.458182-155.461818c96.814545 0 182.458182 69.818182 182.458182 155.461818 0 47.476364-31.650909 90.298182-75.403637 122.88z"
                  fill="#50B674"
                  p-id="1544"
                />
              </svg>`,
        },
        // {
        //   label: "registry",
        //   icon: `<svg t="1723912428087" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4465" width="40" height="40">
        //   <path d="M827.5 777.4c60.5-71.8 97-164.4 97-265.4 0-227.4-185.1-412.5-412.5-412.5S99.5 284.6 99.5 512 284.6 924.5 512 924.5c121 0 230-52.4 305.5-135.6l2.7 1.5 7.3-13zM512 361.2c28.7 0 57.2-1.8 85.4-5.3 10.1 47.5 16 97.1 17.2 147.9H415.5c1.2-50.5 7-99.9 17-147.2 26.2 3.1 52.8 4.6 79.5 4.6z m-70.1-43.8C458.8 255 483.4 197 515 146c31.5 50.8 56 108.5 72.9 170.7-25.1 2.9-50.4 4.4-75.9 4.4-23.6 0-46.9-1.2-70.1-3.7z m212.8 186.4c-1.2-52.7-7.2-104.4-17.6-154 61.3-11.3 120.3-30.9 174.9-58.3 44 59.7 70.7 133 72.4 212.3H654.7z m-279.3 0H139.7c1.7-79.3 28.4-152.6 72.4-212.3 56.4 28.3 117.3 48.3 180.7 59.4-10.4 49.2-16.2 100.5-17.4 152.9z m0 40.1c1.1 46.8 6 93 14.5 137.8-60.7 10.9-119.2 30-173.4 56.7-42.1-54.8-69.3-121.7-75.5-194.4h234.4z m40.1 0h199.1c-1.1 45.1-5.9 89.6-14.2 132.5-29.1-3.7-58.6-5.7-88.4-5.7-27.7 0-55.2 1.7-82.4 4.9-8.3-42.6-13-86.8-14.1-131.7z m239.2 0H883c-6.2 72.7-33.4 139.6-75.5 194.4-52.5-25.8-108.9-44.5-167.5-55.6 8.6-45 13.6-91.6 14.7-138.8z m131-284.2c-49.5 23.9-102.7 41-157.9 51-16-60.5-38.9-117.3-68.1-168.1 88.9 11.5 168.1 54.3 226 117.1zM470.8 141.9c-29.6 51.4-52.8 108.7-68.9 169.9-57.3-9.8-112.4-27.3-163.7-52.1 59.4-64.3 141.1-107.7 232.6-117.8zM243.5 769.8c48.7-22.9 100.8-39.3 154.8-48.9 13.8 56.8 33.5 110.6 58.6 159.4-83.2-12.4-157.6-52.4-213.4-110.5z m261.2 114.5c-29-50.7-51.3-108.1-66.5-169.2 24.4-2.8 49-4.2 73.8-4.2 26.8 0 53.5 1.6 79.7 4.9-15.1 60.8-37.5 117.9-66.3 168.4-4.5 0.2-8.9 0.2-13.4 0.2-2.4 0-4.9 0-7.3-0.1z m68.9-5c24.7-48.3 44.2-101.4 57.9-157.4 52 9.7 102.1 25.8 149 47.9-54.3 56.5-126.3 96-206.9 109.5z" fill="#fff" p-id="4466"></path></svg>`,
        //   classes: {
        //     registry: true,
        //   },
        // },
      ],
      currentTab: "phone",
      userAgreement: [],
      agree: false,
    };
  },
  methods: {
    toggleTabItem(tabItem) {
      this.currentTab = tabItem;
    },
    // 获取用户协议
    async ListRichTextLogin() {
      const { data } = await instance.get("/ListRichText", {
        params: {
          position: "登录页",
        },
      });

      this.userAgreement = data;
    },
  },
  async created() {
    if (this.querys.is_registry == "1") {
      this.currentTab = "phone";
    }
    if (this.querys.alert == "1" && this.querys.msg) {
      this.$message.warning(this.querys.msg);
    }
    this.ListRichTextLogin();
  },
  computed: {
    ...mapGetters(["getPlatformInfo"]),
    querys() {
      return this.$route.query || {};
    },
  },
};
</script>

<style lang="less" scoped>
.bottom-tabs {
  padding: 0 40px;

  .tab-item {
    width: 40%;
    line-height: 42px;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
    text-align: center;
    font-size: 14px;
  }

  .tab-item.active::after {
    content: "";
    width: 30px;
    height: 2px;
    border-radius: 100px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: calc(50% - 15px);
  }
}
</style>

<style lang="less" scoped>
@import url(./../styles/variable.less);

.icp_info {
  position: fixed;
  bottom: 7px;

  a {
    text-decoration: none;

    &:hover,
    &:hover span {
      color: steelblue;
      text-decoration: underline;
    }
  }
}

.wechat_code {
  width: 70%;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.form {
  animation: _show_translateY 0.4s;
}

.form-title-top {
  color: @white6;
  font-size: 14px;
  padding: 0 12px;
  line-height: 30px;
  border-radius: 5px;
  background-color: @white02;
}

._loading_anis {
  padding-bottom: 5px;
  user-select: none;

  span:nth-child(1) {
    animation: _loading_anis 0.4s infinite;
  }

  span:nth-child(2) {
    animation: _loading_anis 0.4s infinite;
    animation-delay: 0.1s;
  }

  span:nth-child(3) {
    animation: _loading_anis 0.4s infinite;
    animation-delay: 0.2s;
  }
}

.hover_filter {
  transition: 0.2s;

  &:hover {
    filter: brightness(120%) !important;
  }

  &:active {
    filter: brightness(80%) !important;
  }
}

.hover_color {
  transition: 0.2s;

  &:hover {
    background-color: @white05 !important;
  }

  &:active {
    background-color: @white1 !important;
  }
}

.login-page {
  .login-container {
    width: 460px;
    border-radius: @radius;
    background-color: @backgroundColor;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 25px 27px 5px;

    .tab-container {
      margin-bottom: 15px;
      margin-top: 25px;
    }

    .form_item {
      margin-bottom: 20px;

      .label {
        margin-bottom: 10px;
      }

      .input input {
        width: 100%;
        height: @itemHeight;
        border-radius: 5px;
        border: none;
        background-color: rgba(255, 255, 255, 0.06);
        padding: 0 20px;
        font-size: 15px;
      }

      .captcha_input {
        input {
          padding-right: 180px;
        }

        .captcha_image {
          position: absolute;
          bottom: 0;
          right: 0;
          border-radius: 5px;
          background-color: rgba(255, 255, 255, 0.04);
          width: 160px;
          height: 100%;
          cursor: pointer;
        }

        .send_captcha {
          background-color: transparent;
          transition: 0.2s;

          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 70%;
            top: 15%;
            left: 0;
            background-image: linear-gradient(
              to bottom,
              transparent,
              rgba(255, 255, 255, 0.1),
              transparent
            );
          }
        }
      }
    }

    .title-top {
      font-size: 20px;
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-bottom: @borderBottom;

      span {
        color: @white6;
      }
    }

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      background: bottom right no-repeat;
      transform: scale(1.4);
      opacity: 0.02;
    }
  }

  .theme_color {
    color: @themeColor !important;
  }
}
</style>
