<template>
  <div class="comp-user-agreement">
    <div class="userAgreement mt" v-if="userAgreement && userAgreement.length">
      <input type="checkbox" class="checkbox" :checked="value" @input="input" />
      <span class="text-sm">我已认真阅读，理解并同意</span>

      <span style="line-height: 20px;" v-for="(el, i) in userAgreement" :key="i" class="item text-sm" @click="openMarkdownPreview(el)">
        <template v-if="el.status">《{{ el.title }}》</template>
      </span>
    </div>

    <markdown-preview :content="markdown" :show="previewDialog" @close="previewDialog = false"></markdown-preview>
  </div>
</template>

<script>
import markdownPreview from "@/components/markdownPreview.vue"

export default {
  props: ["userAgreement", "value"],
  components: { markdownPreview },
  data() {
    return {
      markdown: "",
      previewDialog: false,
    }
  },
  methods: {
    input(e) {
      this.$emit("input", e.target.checked)
    },
    openMarkdownPreview({ content }) {
      this.markdown = content
      this.previewDialog = true
    },
  },
  created() {
    console.log(this.value)
  }
}
</script>

<style lang="less" scoped>
@import url(@/styles/variable.less);

.userAgreement {
  width: 100%;
  text-align: center;
  margin-top: 30px;

  .checkbox {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  span {
    color: rgba(255, 255, 255, 0.6);
  }

  span.item {
    color: @themeColor;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>