<template>
  <div class="login_component component_bottom_bar">
    <div
      class="submit submit_login_phone hover_filter"
      @click="submit_"
      v-if="button_show !== false"
    >
      <div class="captcha_image send_captcha flex center _loading_anis" v-show="submit_loading">
        <span>·</span>
        <span>·</span>
        <span>·</span>
      </div>
      <span v-show="!submit_loading">{{ submit_text }}</span>
    </div>

    <div class="userAgreement mt" v-if="userAgreement&&userAgreement.length">
      <input type="checkbox" class="checkbox" :checked="value" @input="input" />
      <span>我已认真阅读，充分理解并同意</span>

      <span v-for="(el, i) in userAgreement" :key="i" class="item" @click="openMarkdownPreview(el)">
        <template v-if="el.status">《{{ el.title }}》</template>
      </span>
    </div>

    <markdown-preview :content="markdown" :show="previewDialog" @close="previewDialog = false"></markdown-preview>

    <div class="flex center other_login mt2">
      <blockquote @click="click_left">
        <div class="other_item" v-if="button_left === 'username'">微信扫码登录</div>
        <div class="other_item" v-else-if="button_left === 'phone'">手机号快捷登录</div>
      </blockquote>

      <span class="ml mr">|</span>

      <div class="other_item flex">
        <blockquote @click="click_right">
          <span class="theme_color" v-if="button_right === 'register'">没有账号？去注册</span>
          <span class="theme_color" v-else-if="button_right === 'username'">微信扫码登录</span>
          <div class="theme_color" v-else-if="button_right === 'phone'">手机号快捷登录</div>
        </blockquote>

        <div class="icon flex" style="margin-left: 5px">
          <svg
            width="18"
            height="18"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36 24.0083H12"
              stroke="royalblue"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24 12L36 24L24 36"
              stroke="royalblue"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui"
import markdownPreview from "@/components/markdownPreview.vue"
export default {
  components: { markdownPreview },
  props: [
    "button_left",
    "button_right",
    "submit_text",
    "submit_loading",
    "button_show",
    "userAgreement",
    "value",
  ],
  data() {
    return {
      markdown: "",
      previewDialog: false,
    }
  },
  methods: {
    openMarkdownPreview({ content }) {
      this.markdown = content
      this.previewDialog = true
    },

    submit_() {
      if (!this.value) {
        return Message.warning("请先阅读并同意用户协议")
      }
      this.$emit("submit_")
    },

    click_left() {
      this.$emit("click_left")
    },

    click_right() {
      this.$emit("click_right")
    },

    input(e) {
      this.$emit("input", e.target.checked)
    },
  },
}
</script>

<style lang="less" scoped>
@import url(./../../styles/variable.less);

.userAgreement {
  text-align: center;

  .checkbox {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  span {
    color: rgba(255, 255, 255, 0.6);
  }

  span.item {
    color: @themeColor;
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.other_login {
  user-select: none;

  .other_item {
    cursor: pointer;
    color: @white6;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: @white2;
  }
}

.submit {
  width: 100%;
  height: @itemHeight;
  line-height: @itemHeight;
  background-color: @themeColor;
  border-radius: 7px;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}

@keyframes _loading_anis {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 4px);
  }
  100% {
    transform: translate(0, 0);
  }
}

._loading_anis {
  padding-bottom: 5px;
  user-select: none;

  span:nth-child(1) {
    animation: _loading_anis 0.4s infinite;
  }

  span:nth-child(2) {
    animation: _loading_anis 0.4s infinite;
    animation-delay: 0.1s;
  }

  span:nth-child(3) {
    animation: _loading_anis 0.4s infinite;
    animation-delay: 0.2s;
  }
}

.hover_filter {
  transition: 0.2s;

  &:hover {
    filter: brightness(120%) !important;
  }

  &:active {
    filter: brightness(80%) !important;
  }
}

.hover_color {
  transition: 0.2s;

  &:hover {
    background-color: @white05 !important;
  }

  &:active {
    background-color: @white1 !important;
  }
}

.theme_color {
  color: @themeColor !important;
}
</style>
