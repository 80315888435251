<template>
  <div class="easy_page">
    <div class="top_title">公告列表</div>

    <div class="notice_list">
      <div class="notice_item" v-for="(el, i) in noticeList" :key="i" @click="DetailNotice(el)">
        <div class="title">{{ el.notice_title }}</div>
        <div class="content">{{ el.abstract || "暂无内容" }}</div>
        <div class="create_time">{{ el.create_time }}</div>
      </div>
    </div>

    <markdown-preview :content="markdown" :show="previewDialog" @close="previewDialog = false"></markdown-preview>
  </div>
</template>

<script>
import noticeInterface from "@/api/notice"
import markdownPreview from '@/components/markdownPreview.vue'
import instance from '@/utils/request'

export default {
  components: { markdownPreview },
  data() {
    return {
      params: {
        page_num: 1,
        page_size: 10,
      },
      total: 0,
      noticeList: [],
      markdown: "",
      previewDialog: "",
    }
  },
  methods: {
    async ListNotice() {
      const res = await noticeInterface.ListNotice(this.params)
      this.noticeList = res.data.noticeList
      this.total = res.data.total
    },
    async DetailNotice({ sys_notice_id }) {
      const { data } = await instance.get("/DetailNotice", {
        params: { sys_notice_id }
      })
      this.markdown = data.notice_content
      this.previewDialog = true
    }
  },
  created() {
    this.ListNotice()
  },
}
</script>

<style lang="less" scoped>
.top_title {
  margin-bottom: 30px;
  text-align: center;
  font-size: 22px;
}
.notice_list {
  height: 800px;
  overflow: auto;

  .notice_item {
    display: inline-block;
    width: calc(50% - 20px);
    margin: 0 10px 20px;
    background-color: rgba(255, 255, 255, 0.03);
    border-radius: 10px;
    padding: 30px;
    cursor: pointer;
    transition: .2s;

    &:hover {
      background-color: rgba(255,255,255,.06);
    }

    .title {
      font-size: 20px;
      margin-bottom: 18px;
      border-bottom: 1px solid rgba(255,255,255,.04);
      padding-bottom: 12px;
    }

    .content {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 10px;
    }

    .create_time {
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
