<template>
  <div class="component_dialog flex center" :class="{ active: state }">
    <div class="dialog_container relative" :style="{ width }">
      <div class="title_bar flex">
        <span>{{ title }}</span>
        <div class="close_button" @click="close">
          <svg
            width="20"
            height="20"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 14L34 34"
              stroke="#fff"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 34L34 14"
              stroke="#fff"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>

      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "state", "width"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.component_dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: none !important;
  z-index: 10;

  &.active {
    display: flex !important;

    & .dialog_container {
      animation: show-animate 0.4s;
    }
  }

  .dialog_container {
    background-color: #1e2336;
    padding: 30px;
    border-radius: 10px;
    max-width: 75%;
    min-width: 540px;
    max-height: 90%;
    overflow: auto;

    .title_bar {
      border-bottom: 1px solid rgba(255, 255, 255, 0.03);
      padding-bottom: 20px;
      margin-bottom: 30px;
    }

    .close_button {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.2);
      cursor: pointer;
      transition: 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
}
</style>
