export default {
  path: '/scene',
  name: 'scene',
  redirect: '/scene/list',
  component: () => import(`@/layout/scene`),
  children: [
    {
      path: "/scene/list",
      name: "scene_list",
      component: () => import(`@/layout/scene/list`),
    },
    {
      path: '/scene/detail/:id',
      name: 'scene_detail',
      component: () => import(`@/layout/scene/detail`),
    },
    {
      path: '/scene/multidimensional_challenges',
      name: 'multidimensional_challenges',
      component: () => import(`@/layout/scene/multidimensional_challenges`),
    },
  ]
}
