<template>
  <div class="comp-wx-code">
    <!-- 微信登录二维码组件 -->
    <img :src="code" />
    <div class="code_container_outer">
      <div id="code_container" class="flex center"></div>
    </div>
  </div>
</template>

<script>
import instance from "@/utils/request"
import store from '@/store'
export default {
  props: ["uri_type"],
  data() {
    return {
      code: "",
      wxloginObject: null,
    }
  },
  methods: {
    // 获取appid和domain
    async getAppidDomain() {
      const { data } = await instance.get("/WechatGetInfo")
      const options = {
        self_redirect: false,
        id: "code_container",
        appid: data.appid,
        scope: "snsapi_login",
        redirect_uri: data.domain + `${this.uri_type}`,
        stylelite: 1,
      }
      if (this.uri_type === "WechatBind") {
        options.state = store.getters.getToken
      }

      this.wxloginObject = new WxLogin(options)
    },
  },
  created() {
    this.getAppidDomain()
  },
}
</script>

<style scoped>
.code_container_outer {
  width: 240px;
  height: 240px;
  overflow: hidden;
  padding-top: 75px;
  padding-left: 6px;
  background-color: #fff !important;
  border-radius: 10px;
}
#code_container {
  /* padding: 35px 35px 20px; */
  border-radius: 18px;
  transform: scale(1.3);
}
</style>
