export default {
  path: '/target',
  name: 'target',
  redirect: '/target/target_list',
  component: () => import(`@/layout/target`),
  children: [
    {
      path: '/target/target_list',
      name: 'target_list',
      component: () => import(`@/layout/target/target_list`),
    },
    {
      path: '/target/simulation_list',
      name: 'simulation_list',
      component: () => import(`@/layout/target/simulation_list`),
    },
    {
      path: '/target/target_detail/:id',
      name: 'target_detail',
      component: () => import(`@/layout/target/target_detail`),
    },
    {
      path: '/target/kvm_detail/:id',
      name: 'kvm_detail',
      component: () => import(`@/layout/target/simulation_detail`),
    },
  ]
}
