<template>
  <div class="comp markdown-preview flex center" :class="{ show }" @click="close">
    <div class="preview" @click.stop>
      <v-md-editor class="small-pad editor" :value="content" mode="preview"></v-md-editor>
      <div class="flex center">
        <div class="close" @click="close">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import VMdEditor from "@kangc/v-md-editor"
import "@kangc/v-md-editor/lib/style/base-editor.css"
import VuePressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js"
import "@kangc/v-md-editor/lib/theme/style/vuepress.css"
import Prism from "prismjs"

VMdEditor.use(VuePressTheme, {
  Prism,
})
Vue.use(VMdEditor)

export default {
  props: ["content", "show"],
  methods: {
    close() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped lang="less">
@import "@/styles/variable.less";

.preview {
  width: 70%;
  max-height: 90%;
  overflow: auto;
  border-radius: 10px;
  background-color: var(--pagecolor) !important;
}

.markdown-preview {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 11;

  &.show {
    display: flex;
  }

  .close {
    cursor: pointer;
    width: 120px;
    line-height: 40px;
    border-radius: 8px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.05);
    transition: 0.2s;
    margin-bottom: 30px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
}
</style>
