export default {
  path: '/user_info',
  name: 'user_info',
  redirect: '/user_info/avatarnickname',
  component: () => import(`@/layout/user_info`),
  children: [
    {
      path: '/user_info/transaction',
      name: 'transaction',
      component: () => import(`@/layout/user_info/transaction`),
    },
    {
      path: '/user_info/consumption',
      name: 'consumption',
      component: () => import(`@/layout/user_info/consumption`),
    },
    {
      path: '/user_info/bindphone',
      name: 'bindphone',
      component: () => import(`@/layout/user_info/bindphone`),
    },
    {
      path: '/user_info/bindwechat',
      name: 'bindwechat',
      component: () => import(`@/layout/user_info/bindwechat`),
    },
    {
      path: '/user_info/avatarnickname',
      name: 'avatarnickname',
      component: () => import(`@/layout/user_info/avatarnickname`),
    },
    {
      path: '/user_info/logoff',
      name: 'logoff',
      component: () => import(`@/layout/user_info/logoff`),
    },
  ]
}
