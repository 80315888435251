export default {
  path: '/my_record',
  name: 'my_record',
  redirect: '/my_record/my_record_index',
  component: () => import(`@/layout/my_record`),
  children: [
    {
      path: '/my_record/my_record_index',
      name: 'my_record_index',
      component: () => import(`@/layout/my_record/my_record_index`),
    },
    {
      path: '/my_record/collection',
      name: 'collection',
      component: () => import(`@/layout/my_record/collection`),
    },
    {
      path: '/my_record/my_record_msg',
      name: 'my_record_msg',
      component: () => import(`@/layout/my_record/my_record_msg`),
    },
    {
      path: '/my_record/my_record_badge',
      name: 'my_record_badge',
      component: () => import(`@/layout/my_record/my_record_badge`),
    },
    {
      path: '/my_record/daybook_history',
      name: 'daybook_history',
      component: () => import(`@/layout/my_record/daybook_history`),
    },
    {
      path: '/my_record/creator',
      name: 'creator',
      component: () => import(`@/layout/my_record/creator`),
    },
    {
      path: '/my_record/invoice',
      name: 'invoice',
      component: () => import(`@/layout/my_record/my_record_invoice`),
    },
  ]
}
