<template>
  <div class="outer_scale_box">
    <div class="ScaleBox" ref="ScaleBox" :class="align">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScaleBox",
  props: {},
  data() {
    return {
      scale: 0,
      metaHeight: 1080,
      height: 1080,
      width: 1920,
      computedWidth: 0, // 动态计算的宽度
      align: "",
    }
  },
  mounted() {
    // this.setScaleTest()
    // window.addEventListener("resize", this.debounce(this.setScaleTest))
  },
  methods: {
    getScale() {
      // 固定好宽高比，计算出最合适的缩放比
      const { width, height } = this
      const wh = window.innerHeight / height
      const ww = window.innerWidth / width
      // console.log(ww < wh ? ww : wh)
      return ww < wh ? ww : wh
    },
    setScale() {
      // 获取到缩放比例，设置它
      this.scale = this.getScale()
      if (this.$refs.ScaleBox) {
        this.$refs.ScaleBox.style.setProperty("--scale", this.scale)
      }
    },
    getScaleTest() {
      // 计算缩放比例
      const { width, height } = this
      const computedHeight = window.innerHeight
      const computedWidth = (width / height) * computedHeight

      // 缩放比例应基于高度，而非宽度
      return computedHeight / this.metaHeight
    },
    setScaleTest() {
      // 设置页面高度为100vh，并根据高度计算宽度
      this.height = window.innerHeight // 设置高度为 100vh
      this.computedWidth = (this.width / this.height) * window.innerHeight

      // 获取并设置缩放比例
      this.scale = this.getScaleTest()
      this.setAlign()
    },
    debounce(fn, delay) {
      return function () {
        const th = this
        const args = arguments
        fn.apply(th, args)
      }
    },
    // 设置对其方式
    setAlign() {
      if (
        (this.computedWidth * this.scale).toFixed(2) < window.innerWidth ||
        this.$route.path === "/login"
      ) {
        this.align = "center"
      } else {
        this.align = "left"
      }
    },
  },
  computed: {
    routePath() {
      return this.$route.path
    }
  },
  watch: {
    scale(val) {
      this.$nextTick(() => {
        this.$refs.ScaleBox.style.setProperty("--scale", val)
      })
    },
    routePath(val) {
      this.setAlign()
    }
  },
}
</script>

<style lang="less" scoped>
.outer_scale_box {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(255,255,255,.15);
  }
}

</style>
