import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import layout from '@/layout'
import myRecord from './modules/my-record'
import target from './modules/target'
import scene from './modules/scene'
import activity from './modules/activity'
import rank from './modules/rank'
import userInfo from './modules/user-info'
import instance from '@/utils/request'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/my_record/my_record_index',
    component: layout,
    children: []
  }
]

// 读取 layout 文件夹下的文件
const layoutFiles = require.context('@/layout', true, /^\.\/[^/]+\.vue$/)
layoutFiles.keys().forEach(fileName => {
  const name = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')

  if (name !== 'index') {
    routes[0].children.push({
      path: `/${name}`,
      name: name,
      component: () => import(`@/layout/${name}.vue`)
    })
  }
})

// 读取根目录下的文件
const rootFiles = require.context('@/views', true, /\.vue$/)
rootFiles.keys().forEach(fileName => {
  const name = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '');

  routes.push({
    path: `/${name}`,
    name: name,
    component: () => import(`@/views/${name}.vue`)
  })
})

// 子路由
routes[0].children.push(myRecord)
routes[0].children.push(target)
routes[0].children.push(scene)
routes[0].children.push(activity)
routes[0].children.push(userInfo)
routes[0].children.push(rank)

const router = new VueRouter({
  routes
})

/**
 * router -> 路由对象
 * store -> 状态管理器
 */
async function getUnreadNumber() {
  const { data } = await instance.get("/GetUnreadMessageNumber", {
    loading: false,
  })

  store.dispatch("setUnreadsSync", data.number)
}

const jumps = ["/test", "/loginTokenSync", "/activity/gold_products"]
router.beforeEach((to, from, next) => {
  if (jumps.includes(to.path)) return next()
  const isAuthenticated = !!store.getters.getToken

  if (isAuthenticated && to.path !== from.path) {
    to.path !== '/login' && getUnreadNumber()
    next()
  }
  else {
    if (to.path !== '/login' && to.path !== from.path) {
      next('/login')
    } else {
      to.path !== '/login' && getUnreadNumber()
      next()
    }
  }
})

export default router
