import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const TOKEN_KEY = "$_TARGET_FIGMA_FRONT_TOKEN_$"
const INFO_KEY = "$_TARGET_FIGMA_FRONT_USER_INFO_$"

// 动态getters管理器
const gettersManager = states => {
  const getters = {}

  Object.keys(states).forEach(el => {
    getters[`get${ el.charAt(0).toUpperCase() + el.slice(1) }`] =
    function(state) {
      return state[el]
    }
  })

  return getters
}

// 动态setters管理器
const settersManager = states => {
  const setters = {}

  Object.keys(states).forEach(el => {
    setters[`set${ el.charAt(0).toUpperCase() + el.slice(1) }`] =
    function(state, val) {
      state[el] = val
    }
  })

  return setters
}

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem(TOKEN_KEY),
    info: localStorage.getItem(INFO_KEY) ? JSON.parse(localStorage.getItem(INFO_KEY)) : {},
    messageList: [],
    unreads: 0,
    platformInfo: {},
    globalLoading: false,
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getInfo(state) {
      return state.info
    },
    getMessageList(state) {
      return state.messageList
    },
    getUnreads(state) {
      return state.unreads
    },
    getPlatformInfo(state) {
      return state.platformInfo
    },
    getGlobalLoading(state) {
      return state.globalLoading
    },
  },
  mutations: {
    setToken(state, val) {
      state.token = val
    },
    setInfo(state, val) {
      state.info = val
    },
    setMessageList(state, val, clear) {
      clear && (state.messageList = [])
      val && (state.messageList.unshift(val))
    },
    setUnreads(state, val) {
      state.unreads = val
    },
    setPlatformInfo(state, val) {
      state.platformInfo = val
    },
    setGlobalLoading(state, val) {
      state.globalLoading = val
    },
  },
  actions: {
    setTokenSync(context, val) {
      context.commit("setToken", val)
      sessionStorage.setItem(TOKEN_KEY, val)
      // localStorage.setItem(TOKEN_KEY, val)
    },
    setInfoSync(context, val) {
      context.commit("setInfo", val)
      localStorage.setItem(INFO_KEY, JSON.stringify(val))
    },
    setMessageListSync(context, messageObject, clear) {
      context.commit("setMessageList", messageObject, clear)
    },
    setUnreadsSync(context, val) {
      context.commit("setUnreads", val)
    },
    setPlatformInfoSync(context, val) {
      context.commit("setPlatformInfo", val)
    },
    setGlobalLoadingSync(context, val) {
      context.commit("setGlobalLoading", val)
    },
  },

  modules: {
    
  }
})
