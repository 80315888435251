export const xwwwForm = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  }
}

export const formData = {
  headers: {
    "Content-Type": "multipart/form-data"
  }
}
