export default {
  path: '/ranking',
  name: 'ranking',
  redirect: '/ranking/score_rank',
  component: () => import(`@/layout/rank`),
  children: [
    {
      path: '/ranking/score_rank',
      name: 'score_rank',
      component: () => import(`@/layout/rank/scoreRank`),
    },
  ]
}
