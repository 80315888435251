<template>
  <div class="comp comp-registry form register_form">
    <div class="form_item">
      <div class="label">手机号码</div>
      <div class="input">
        <input type="text" v-model="ruleForm.phone" placeholder="请输入手机号码" />
      </div>
    </div>

    <div class="form_item">
      <div class="label">图形验证码</div>
      <div class="input captcha_input relative">
        <input type="text" v-model="ruleForm.captcha_value" placeholder="请输入图形验证码" />
        <img class="captcha_image" @click="GetImageCaptcha" :src="captcha_data" v-if="captcha_data" />
        <div class="captcha_image flex center _loading_anis" v-else>
          <span>·</span>
          <span>·</span>
          <span>·</span>
        </div>
      </div>
    </div>

    <div class="form_item">
      <div class="label">短信验证码</div>
      <div class="input captcha_input relative">
        <input type="text" v-model="ruleForm.captcha" placeholder="请输入短信验证码" />

        <div class="captcha_image send_captcha flex center _loading_anis" v-show="send_loading">
          <span>·</span>
          <span>·</span>
          <span>·</span>
        </div>

        <div
          class="captcha_image send_captcha flex center hover_color"
          v-show="!send_loading"
          @click="SendPhoneCaptcha(0)"
        >{{ captchaNumber ? captchaNumber + "s" : "发送验证码" }}</div>
      </div>
    </div>

    <div class="_submit flex center mt2" @click="registryRequest">注册</div>
  </div>
</template>

<script>
import { RegistryByPhone } from '@/api/user.js'
import captchaMixin from "./../mixins/captcha.js"

export default {
  mixins: [captchaMixin],
  props: ["agree"],
  methods: {
    // 通过手机号码注册
    async registryRequest() {
      if (!this.agree) return this.$message.warning("请先阅读并同意用户协议")
      const validResult = this.ruleFormValid(this.ruleForm)
      if (!validResult) return;

      // 注册所需参数(phone, captcha, [access_token, openid])
      const res = await RegistryByPhone({
        phone: this.ruleForm.phone,
        captcha: this.ruleForm.captcha,
        access_token: this.access_token,
        openid: this.openid,
      })
      this.$message.success("注册成功")
      this.$emit("toggleTab", "phone")
    },
  },
  computed: {
    access_token() {
      return this.$route.query.access_token || null
    },
    openid() {
      return this.$route.query.openid || null
    }
  },
  created() {
    
  }
}
</script>

<style lang="less" scoped>
@import url('./../mixins/form.less');
</style>
