import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/assets/tailwindcss.css'
import '@/styles/common.less'
import '@/styles/el-common.less'
import '@/styles/variable.less'
import uploader from 'vue-simple-uploader'

Vue.use(uploader)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
