<template>
  <div id="app" v-loading="getGlobalLoading">
    <router-view />
  </div>
</template>

<script>
import scaleBox from "@/components/scaleBox.vue"
import store from "@/store"
import instance from "@/utils/request"
import { mapGetters } from "vuex"

export default {
  components: { scaleBox },
  methods: {
    async ListPlatformInformation_() {
      const { data } = await instance.get("/ListPlatformInformation")

      document.title = data.website_title
      store.dispatch("setPlatformInfoSync", data)
    },
    handleBeforeUnload() {
      const navigationType = performance.getEntriesByType("navigation")[0].type

      if (navigationType !== "reload") {
        store.dispatch("setTokenSync", "")
      }
    },
  },
  computed: {
    ...mapGetters(["getGlobalLoading"]),
  },
  mounted() {
    // window.addEventListener("beforeunload", this.handleBeforeUnload)
  },
  beforeDestroy() {
    // window.removeEventListener("beforeunload", this.handleBeforeUnload)
  },
  created() {
    this.ListPlatformInformation_()
  },
}
</script>

<style lang="less">
body {
  height: 100vh;
}
</style>
