import instance from "@/utils/request"

function interfaceOrigin(type, apiList) {
  const typeLabels = [
    { type: ["get", "delete"], label: "params" },
    { type: ["post", "put"], label: "data" },
  ]
  const requestDataAttr = typeLabels.filter(el => el.type.indexOf(type) > -1)[0].label
  const apiInstance = {}

  apiList.forEach(el => {
    let replace_url = `/${el}`, replace_config = {}, replace_attr = el

    if (el.constructor === Object) {
      replace_url = el.url.replace("$", el.attr)

      el.config && (replace_config = el.config)
      replace_attr = el.attr
    }

    apiInstance[replace_attr] = function (paramsOrData) {
      return instance({
        method: type,
        url: replace_url,
        [requestDataAttr]: paramsOrData,
        ...replace_config,
      })
    }
    apiInstance[replace_attr]._name = replace_attr
  })

  return apiInstance
}

export const interfaceGets =
(apiList) => interfaceOrigin("get", apiList)

export const interfacePosts =
(apiList) => interfaceOrigin("post", apiList)

export const interfacePuts =
(apiList) => interfaceOrigin("put", apiList)

export const interfaceDeletes =
(apiList) => interfaceOrigin("delete", apiList)
